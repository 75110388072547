import React from "react";
import "./Prompt-style.css";
import { Button } from "antd";

interface PopupProps {
  message: string;
  showButton: boolean;
}

const Prompt: React.FC<PopupProps> = ({ message, showButton }) => {
  const handleButtonClick = () => {
    window.location.reload();
  };

  return (
    <a className="popup">
      <div className="popup-inner">
        <div className="popup-content">
          <p>{message}</p>
          {showButton && (
            <Button
              className="bg-primary text-white border-primary propmtactionBtn"
              onClick={handleButtonClick}
            >
              OK
            </Button>
          )}
        </div>
      </div>
    </a>
  );
};

export default Prompt;
