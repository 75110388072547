import React from "react";
import { Image } from "antd";
import "../../global.css";
import "./Banner-styles.css";
import { Button } from "antd";
import Typewriter from "../typeWriter-effect-component/typewriter";
// import Div100vh from 'react-div-100vh'

function Banner() {
  function appHeight() {
    const doc = document.documentElement;
    doc.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
  }

  window.addEventListener("resize", appHeight);
  appHeight();
  return (
    <div id="banner">
      <div className="bg-darkblue bannerOuter font-poppins">
        {/* <div className="bg-darkblue bannerOuter font-poppins"> */}
        <div className="bannerContent">
          <div className="bannerItems">
            <div className="bannerHeroQuoteOuter">
              <p>
                <span className="text-white ">Your</span>{" "}
                <span className="text-secondary">
                  Digital Product Engineering{" "}
                </span>
              </p>
              <p className="text-white">Powerhouse</p>
            </div>

            <div className="subtext text-white">
              <p>
                Partner with us to create the most innovative, engaging and
                impactful digital solutions.
              </p>
            </div>
            <div>
              <Typewriter></Typewriter>
            </div>

            <div className="bannerButtons">
              <Button
                href="#solution-delivery"
                type="default"
                className="bg-primary text-white border-primary ourServicesBtn bannerBtn"
              >
                Our Services
              </Button>
              <Button
                href="#contact"
                type="default"
                className="bg-white text-darkblue-bg sayHiBtn bannerBtn"
              >
                Say Hi!
              </Button>
            </div>
          </div>
        </div>
        <img className="decor" src="https://i.imgur.com/uP4eLlz.png" alt="" />
        {/* <img className="bannerimage2  " src="https://i.imgur.com/ZaVa2sw.png" alt="" /> */}

        <img
          className="bannerimage1"
          src="https://i.imgur.com/w7Y3klL.png"
          alt=""
        />
        <img
          className="  flipped"
          src="https://i.imgur.com/jqpAPGd.png"
          alt=""
        />
        {/* </div> */}
      </div>
    </div>
  );
}

export default Banner;
