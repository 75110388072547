import React from "react";
import "../../global.css";
import "./Solution-delivery-styles.css";
import { Button } from "antd";
import { Typography } from "antd";
import { ReactComponent as AiSVG } from "./images/ai.svg";
import { ReactComponent as CloudSVG } from "./images/cloud.svg";
import { ReactComponent as AppSVG } from "./images/app.svg";
import { ReactComponent as MVPSVG } from "./images/mvp.svg";
import { ReactComponent as SoftwareSVG } from "./images/software.svg";
import { ReactComponent as UXSVG } from "./images/ux.svg";

import Solution from "../solution-component/Solution";
const { Title } = Typography;

function SolutionDelivery() {
  const solutionSVGs: any = {
    Ai: <AiSVG></AiSVG>,
    Software: <SoftwareSVG></SoftwareSVG>,
    App: <AppSVG></AppSVG>,
    Cloud: <CloudSVG></CloudSVG>,
    UX: <UXSVG></UXSVG>,
    MVP: <MVPSVG></MVPSVG>,
  };
  const solutionNames: any = {
    Ai: "Data Science & AI",
    Software: "Software Products Engineering",
    App: "Mobile App Development",
    Cloud: "Cloud Enablement",
    UX: "User Experience",
    MVP: "MVP Building",
  };

  return (
    <div className="solutionDeliveryOuter bg-accent">
      <div className="solutionDelivery">
        <div>
          <Title className="text-primary" level={4}>
            What we do
          </Title>
          <Title className="text-darkblue-bg solutionHeader" level={1}>
            Solution Delivery
          </Title>
          <p className="text-black">
            We provide end-to-end digital product engineering services, deploying cutting edge technology most
            suited for your situation and using industry leading best practices. 
          </p>
          <p className="text-black">Our professionals possess deep technical expertise and diverse global experience in designing and delivering software products, while working with entities ranging from start-ups to multinational enterprises.</p>
        </div>
        <div className="solutionGallery">
          {Object.keys(solutionSVGs).map((key) => (
            <Solution solutionName={solutionNames[key]} solutionImg={solutionSVGs[key]} key={key}></Solution>
          ))}
        </div>
      </div>
      <div className="text-primary navDiv">NavDiv</div>
    </div>
  );
}

export default SolutionDelivery;
