import React, { ReactNode, useState, useEffect } from "react";

import "../../App.css";
import "../../global.css";
import { Layout, Menu, Button } from "antd";
import { FloatButton } from "antd";
import Navbar from "../../components/navbar-component/Navbar";
import Banner from "../../components/banner-component/Banner";
import Teststack from "../careers-teststack/techstack";
import SolutionDelivery from "../../components/solution-delivery-component/Solution-delivery";
import Dedicatedteams from "../../components/dedicated-teams-component/Dedicated-teams";
import Contact from "../../components/contact-component/Contact";
import AboutUs from "../../components/about-us-component/About-us";
import Footer from "../../components/footer-component/Footer";
import TeckStack from "../../components/tech-stack-component/Tech-stack";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <div>Hello world!</div>,
  },
]);
function scrollToElement(id: any) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}

function Home() {
  var scrollPercentage: number = 0;
  const [navBarPhase, setNavBarPhase] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const hashTerm = window.location.hash.substring(1);
      if (hashTerm) {
        scrollToElement(hashTerm);
      } else {
        window.scrollTo(0, 0);
      }
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  // Attach a scroll event listener to the window
  window.addEventListener("scroll", () => {
    // Calculate the scroll percentage
    scrollPercentage =
      (window.scrollY /
        (document.documentElement.scrollHeight - window.innerHeight)) *
      100;
    let scrollThreshold: number = 1.0;
    if (scrollPercentage > scrollThreshold) {
      setNavBarPhase(true);
    } else if (scrollPercentage <= scrollThreshold) {
      setNavBarPhase(false);
    }
  });

  return (
    <div className="App">
      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      ></meta>
      <Navbar phase={navBarPhase}></Navbar>

      <Banner></Banner>
      {/* <TeckStack></TeckStack> */}
      {/* <Typewriter></Typewriter> */}
      <div id="about-us"></div>
      <AboutUs />
      <div id="solution-delivery"></div>
      <SolutionDelivery />
      <div id="dedicated-teams"></div>
      <Dedicatedteams />
      <div id="contact">
        <Contact />
      </div>

      <Footer />
    </div>
  );
}

export default Home;
