import * as React from "react";
import "./career-description.css";

interface Description {
  heading: string;
  points: string[];
}

const CareerDescription: React.FC<Description> = ({ heading, points }) => {
  return (
    <div>
      <section className="education-section">
        <header className="section-header">{heading}</header>
        <ul className="section-content">
          {points.map((point, idx) => (
            <li key={idx}>{point}</li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default CareerDescription;
