import React from "react";
import "./work-culture.css";

function WorkCulture() {
  return (
    <>
      <div className="company-culture-container">
        <div className="company-culture-content">
          <div className="company-culture-info">
            <div className="company-culture-header">
              <h2>Workplace & Culture</h2>
            </div>
            <div className="company-culture-description">
              <p>
                While giving you the flexibility of a remote work setup, we
                don’t forget the importance of building team spirit and having
                some fun! All team members are allowed to spend as much time as
                they want at office to meet with their colleagues. We also have
                frequent online events as well as meetups where we go sailing,
                have parties and just have a good time together!
              </p>
            </div>
          </div>
          <div className="company-culture-image">
            <img
              loading="lazy"
              srcSet="https://i.imgur.com/RsbaTQR.jpeg"
              className="culture-image"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default WorkCulture;
