import {
  createBrowserRouter,
  RouterProvider,
  useLoaderData,
  Link,
} from "react-router-dom";

import "./index.css";
import Home from "./components/home-component/home";
import Careers from "./components/careers-component/careers";
import CareerIndividual from "./components/careers-individual-career-component/career-individual";
import React, { useEffect } from "react";
import OGMetaTags from "./components/OGMetaTags-component/OGMetaTags";

let router = createBrowserRouter([
  {
    path: "/",
    Component() {
      return (
        <>
          <OGMetaTags
            title="Onterra Group"
            description="Official Website of Onterra Group"
            imageUrl="https://i.imgur.com/DHEPrdN.png"
          />
          <Home />
        </>
      );
    },
  },
  {
    path: "/careers",
    Component() {
      return (
        <>
          <OGMetaTags
            title="Onterra Group - Careers"
            description="Explore exciting career opportunities at Onterra Group."
            imageUrl="https://i.imgur.com/DHEPrdN.png"
          />
          <Careers />
        </>
      );
    },
  },
  {
    path: "/careers/:id",
    Component() {
      return (
        <>
          <OGMetaTags
            title="Onterra Group - Career Details"
            description="Explore exciting career opportunities at Onterra Group."
            imageUrl="https://i.imgur.com/DHEPrdN.png"
          />
          <CareerIndividual />
        </>
      );
    },
  },
  {
    path: "*",
    Component() {
      return (
        <>
          <OGMetaTags
            title="Onterra Group"
            description="404 - Not Found"
            imageUrl="https://i.imgur.com/DHEPrdN.png"
          />
          <div className="notFoundPage">
            <h1>404 - Page Not Found</h1>
            <p>Sorry, the page you are looking for does not exist.</p>
            Go to <a href="/">Home Page</a>
          </div>
        </>
      );
    },
  },
]);

export default function App() {
  return <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />;
}
