import React from "react";
import { Image } from "antd";
import "../../global.css";
import "./CareersBanner.css";
import { Button } from "antd";

interface CareersBannerProps {
  title?: string;
}

function CareersBanner({ title }: CareersBannerProps) {
  function appHeight() {
    const doc = document.documentElement;
    doc.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
  }

  window.addEventListener("resize", appHeight);
  appHeight();

  return (
    <div id="banner">
      <div className="bg-darkblue bannerOuter font-poppins">
        <div className="bannerContentCareers">
          <div className="bannerItemsCareers">
            <div className="bannerHeroQuoteOuterCareers">
              {!title && (
                <p>
                  <span className="text-white ">Join</span>{" "}
                  <span className="text-secondary">on</span>
                  <span className="text-white">terra</span>
                </p>
              )}
              {title && (
                <p>
                  <span className="text-white developped-by-">{title}</span>
                </p>
              )}
            </div>
            <div className="bannerButtonsCareers">
              {!title && (
                <Button
                  href="/careers#career_list"
                  type="default"
                  className=" text-bold bg-primary text-white border-primary ourServicesBtn bannerBtnCareers"
                >
                  Job Openings
                </Button>
              )}
              {title && (
                <Button
                  href="#form"
                  type="default"
                  className=" text-bold bg-primary text-white border-primary ourServicesBtn bannerBtnCareers"
                >
                  Apply For This Role
                </Button>
              )}
            </div>
          </div>
        </div>

        <img
          className="  bannerimage1Careers"
          src="https://i.imgur.com/Z7rZqSB.jpeg"
          alt=""
        />
      </div>
    </div>
  );
}

export default CareersBanner;
