export const careerData = [
  {
    id: "JD1",
    location: "Remote",
    type: "Full-Time",
    title: "Senior Full-Stack Software Engineer",
    skills:
      "HTML5, CSS, Typescript, React, Node Js/Nest Js, Jest/Mocha, SQL, NoSQL",
    status: "active",
    descriptions: [
      {
        heading: "Duties and Responsibilities",
        points: [
          "Drive software / coding excellence through industry best practices.",
          "Communicate with clients and understand business requirements.",
          "Possess a thorough understanding of the product from both functional and technical perspectives.",
          "Lead technology and development strategy selections based on the business/organization requirements and budgets.",
        ],
      },
      {
        heading: "Education and Experience",
        points: [
          "Bachelor’s Degree in Computer Science or equivalent qualification in a similar field.",
          "Minimum of hands-on experience of 3 years in software development.",
          "Strong knowledge HTML5, CSS and JavaScript/TypeScript or any JS frameworks such as React.",
          "Strong knowledge and experience in technologies such as Node JS /Nest JS.",
          "Sound knowledge in unit testing - Jest /Mocha.",
          "Good understanding in SQL and NoSQL databases.",
          "Strong knowledge in algorithms and data structures.",
          "Experience in cloud services such as AWS is a plus.",
        ],
      },
      {
        heading: "Required Attributes",
        points: [
          "Strong interest in learning and ability to adapt to new technologies.",
          "Ability to lead and mentor a small team.",
          "Excellent communication skills.",
          "Ability to work both independently and as a part of a team.",
        ],
      },
      {
        heading: "Job Type",
        points: ["Full-time / Contract"],
      },
      {
        heading: "Salary / Compensation",
        points: ["US$ 1,100 to US$ 1,900 equivalent"],
      },
    ],
  },
  {
    id: "JD2",
    location: "Remote",
    type: "Full-Time",
    title: "Senior Backend Software Engineer",
    skills:
      "RESTful API, Nest Js, Node Js, Typescript, Postgres, Prisma, SQL, NoSQL, AWS",
    status: "active",
    descriptions: [
      {
        heading: "Duties and Responsibilities",
        points: [
          "Drive software / coding excellence through industry best practices.",
          "Communicate with clients and understand business requirements.",
          "Possess a thorough understanding of the product from both functional and technical perspectives.",
          "Responsible for technology and development strategy selections based on the business/organization requirements and budgets.",
        ],
      },
      {
        heading: "Education and Experience",
        points: [
          "Bachelor’s Degree in Computer Science or equivalent qualification in a similar field.",
          "More than 3 years’ experience in software development and a proven track record with Nest.js",
          "Strong understanding of TypeScript and its core principles.",
          "Proficiency in designing and implementing RESTful APIs.",
          "Knowledge of Node.js and its ecosystem.",
          "Familiarity with databases, including Postgres.",
          "Experience with ORM tools such as Prisma.",
          "Knowledge of server-side caching mechanisms.",
          "Experience in database design, development and SQL queries (NoSQL, Relational).",
          "Experience in design patterns, best practices and TDD/DDD.",
          "Experience in AWS cloud platform is a plus.",
        ],
      },
      {
        heading: "Required Attributes",
        points: [
          "Strong interest in learning and ability to adapt to new technologies.",
          "Ability to lead & mentor a small team.",
          "Excellent communication skills.",
          "Ability to work both independently and as a part of a Team.",
          "Analytical and Problem-solving skills.",
        ],
      },
      {
        heading: "Job Type",
        points: ["Full-time / Contract"],
      },
      {
        heading: "Salary / Compensation",
        points: ["US$ 1,000 to US$ 1,800 equivalent"],
      },
    ],
  },
  {
    id: "JD3",
    location: "Remote",
    type: "Full-Time",
    title: "Senior Frontend Software Engineer",
    skills:
      "HTML5, CSS, Typescript, React, JWT, Jest/Mocha, Babel, Webpack, NPM",
    status: "active",
    descriptions: [
      {
        heading: "Duties and Responsibilities",
        points: [
          "Drive software / coding / design excellence through industry best practices.",
          "Communicate with clients and understand business requirements.",
          "Should possess a thorough understanding of the product from both functional and technical perspectives.",
          "Responsible for technology and development strategy selections based on the business/organization requirements and budgets.",
        ],
      },
      {
        heading: "Education and Experience",
        points: [
          "Bachelor’s Degree in Computer Science or equivalent qualification in a similar field.",
          "Minimum of hands-on experience of 3 years in software development.",
          "Strong knowledge HTML5, CSS and JavaScript/TypeScript and React.",
          "Knowledge of modern authorization mechanisms, such as JSON Web Token.",
          "Sound knowledge in unit testing -Jest /Mocha.",
          "Experience with common front-end development tools such as Babel, Webpack, NPM, etc.",
          "Familiarity with modern front-end build pipelines and tools.",
        ],
      },
      {
        heading: "Required Attributes",
        points: [
          "Strong interest in learning and ability to adapt to new technologies.",
          "Ability to lead & mentor a small team.",
          "Excellent communication skills.",
          "Ability to work both independently and as a part of a Team.",
        ],
      },
      {
        heading: "Job Type",
        points: ["Full-time / Contract."],
      },
      {
        heading: "Salary / Compensation",
        points: ["US$ 1,000 to US$ 1,800 equivalent."],
      },
    ],
  },
  {
    id: "JD4",
    location: "Remote",
    type: "Full-Time",
    title: "Senior UX/UI Designer",
    skills: "Figma, Sketch, Adobe Suit, HTML5, CSS, Javascript",
    status: "active",
    descriptions: [
      {
        heading: "Duties and Responsibilities",
        points: [
          "Develop design strategies that meet the needs of clients, product managers, and other stakeholders.",
          "Work closely with clients and stakeholders to understand their requirements and ensure that design solutions meet their needs.",
          "Develop wireframes, prototypes, and design concepts that effectively communicate design concepts and ideas.",
          "Conduct user research to better understand user needs and preferences, and use that information to inform design decisions.",
          "Work with development teams to ensure that design solutions are technically feasible and can be implemented within the constraints of the project.",
        ],
      },
      {
        heading: "Education and Experience",
        points: [
          "Bachelor's degree in Graphic Design, User Experience, or a related field.",
          "2+ years of experience in UI/UX design, with a focus on designing digital products and services.",
          "Strong portfolio that demonstrates a range of design projects and a deep understanding of UX design principles.",
          "Experience leading design teams, including assigning tasks, providing feedback, and managing timelines and schedules.",
          "Strong communication skills, including the ability to effectively communicate design concepts to non-designers.",
          "Experience conducting user research and using data to inform design decisions.",
          "Proficiency with design tools such as Figma, Sketch, and Adobe Creative Suite.",
          "Strong understanding of web development technologies such as HTML, CSS, and JavaScript.",
          "Ability to work in a fast-paced, deadline-driven environment and manage multiple projects simultaneously.",
        ],
      },
      {
        heading: "Required Attributes",
        points: [
          "Strong interest in learning and ability to adapt to new technologies.",
          "Excellent communication skills.",
          "Ability to work both independently and as a part of a team.",
        ],
      },
      {
        heading: "Job Type",
        points: ["Full-time / Contract."],
      },
      {
        heading: "Salary / Compensation",
        points: ["US$ 700 to US$ 1,200 equivalent."],
      },
    ],
  },
  {
    id: "JD5",
    location: "Remote",
    type: "Full-Time",
    title: "Graphic Designer",
    skills: "Figma, Sketch, Adobe Suit, HTML5, CSS, Javascript, Color Theory",
    status: "inactive",
    descriptions: [
      {
        heading: "Education and Experience",
        points: [
          "Bachelor’s Degree in Computer Science or equivalent qualification in a similar field.",
          "Minimum of hands-on experience of 3 years in software development.",
          "Strong knowledge HTML5, CSS and JavaScript/TypeScript and React.",
          "Knowledge of modern authorization mechanisms, such as JSON Web Token.",
          "Sound knowledge in unit testing -Jest /Mocha.",
          "Experience with common front-end development tools such as Babel, Webpack, NPM, etc.",
          "Familiarity with modern front-end build pipelines and tools",
        ],
      },
      {
        heading: "Additional Requirements",
        points: [
          "Experience with responsive and adaptive design.",
          "Understanding of server-side CSS preprocessors.",
          "Proficient understanding of cross-browser compatibility issues and ways to work around them.",
          "Experience with RESTful services and APIs.",
          "Knowledge of code versioning tools such as Git.",
          "Good understanding of SEO principles and ensuring that application will adhere to them.",
        ],
      },
    ],
  },
];
