import React, { useEffect } from "react";

interface OGMetaTagsProps {
  title: string;
  description: string;
  imageUrl: string;
}

function OGMetaTags({ title, description, imageUrl }: OGMetaTagsProps) {
  useEffect(() => {
    const ogTitle = document.createElement("meta");
    ogTitle.setAttribute("property", "og:title");
    ogTitle.setAttribute("content", title);
    document.head.appendChild(ogTitle);

    const ogDescription = document.createElement("meta");
    ogDescription.setAttribute("property", "og:description");
    ogDescription.setAttribute("content", description);
    document.head.appendChild(ogDescription);

    const ogImage = document.createElement("meta");
    ogImage.setAttribute("property", "og:image");
    ogImage.setAttribute("content", imageUrl);
    document.head.appendChild(ogImage);

    return () => {
      document.head.removeChild(ogTitle);
      document.head.removeChild(ogDescription);
      document.head.removeChild(ogImage);
    };
  }, [title, description, imageUrl]);

  return null;
}

export default OGMetaTags;
