import React from "react";
import "../../global.css";
import  "./Dedicated-teams-styles.css";
import { Button } from "antd";
import { Typography } from "antd";
import { ReactComponent as FindSVG } from "./images/find.svg";
import { ReactComponent as OnboardSVG } from "./images/onboard.svg";
import { ReactComponent as SelectSVG } from "./images/select.svg";
import { ReactComponent as StartSVG } from "./images/start.svg";
import { ReactComponent as ArrowSVG } from "./images/arrowHead.svg";

import Solution from "../solution-component/Solution";
const { Title } = Typography;

function DedicatedTeams() {
  const solutionSVGs: any = {
    one: <SelectSVG></SelectSVG>,
    two: <OnboardSVG></OnboardSVG>,
    three: <StartSVG></StartSVG>,
  };
  const solutionNames: any = {
    one: "Select Your offshore teammate",
    two: "Onboard",
    three: "Start Working",
  };

  return (
    <div className="solutionDeliveryOuter dedicatedOuter bg-accent">
      <div className="solutionDelivery">
        <div>
          
          <Title className="text-darkblue-bg deidcatedHeader" level={1}>
            Dedicated Teams
          </Title>
          <p className="text-black">
          Do you need to scale up your tech team fast while retaining an optimum level of flexibility?  Are you looking for a trusted and secure partner to augment your offshore team? We are ready to solve your needs whether it be a single resource, multiple resources or a fraction of a resource. </p>
          <p className="text-black">
          We headhunt, recruit and manage remote high-quality developers, UX designers and data scientists based on your needs. All professionals work from Colombo - Sri Lanka, but work with and report directly into your organisation.
          </p>
        </div>
        <div className="stepGallery">
            <div className="stepOuter">
              <div className="stepSVG">{solutionSVGs['one']}</div>

              <div className="stepName text-darkblue-bg"><p>{solutionNames['one']}</p></div>

            </div>
            <div className="arrowSVG"><ArrowSVG></ArrowSVG></div>
            
            <div className="stepOuter">
              <div className="stepSVG">{solutionSVGs['two']}</div>

              <div className="stepName text-darkblue-bg"><p>{solutionNames['two']}</p></div>

            </div>
            <div className="arrowSVG"><ArrowSVG></ArrowSVG></div>

            <div className="stepOuter">
              <div className="stepSVG">{solutionSVGs['three']}</div>

              <div className="stepName text-darkblue-bg"><p>{solutionNames['three']}</p></div>

            </div>

        </div>
      </div>
    </div>
  );
}

export default DedicatedTeams;
