import React from "react";
import "../../global.css";
import "./About-us-styles.css";
import { Button } from "antd";
import TeckStack from "../tech-stack-component/Tech-stack";

function AboutUs(props: any) {
  return (
    <div className=" bg-darkblue AboutUsOuter text-bold">
      <TeckStack></TeckStack>
      <div className="aboutUsContent">
       <div className=" quoteOuter text-accent">
        <p className="line1 quoteElement">We <span className="text-secondary">Design,</span></p>
        <p className="line2 quoteElement"><span className="spaceDesktop"></span><span className="text-secondary">Build &</span> <span className="text-secondary">Scale</span></p>
        <p className="line3 quoteElement"><span className="spaceDesktop"></span>Next Generation</p>
        <p className="line4 quoteElement"><span className="spaceDesktop"></span><span className="">Digital Products</span></p>
       </div>
       <div className="aboutUsDotDotDot text-accent"></div>
       
       <div>
        <p className="aboutUsPara text-accent"><span className="text-bold text-secondary">On</span><span className="text-bold text-accent">terra</span><span></span> delivers solutions aligned with your ambitions, budgets, and timelines. We partner with you from inception to subsequent execution and  scaling of the solution.<br></br> We also offer efficient ways to scale your tech team with dedicated resources from Sri Lanka, seamlessly integrated into your organization.</p>
       </div>
       <div id="abt" className="text-primary navDiv">hi</div>

       </div>
    </div>
  );
}

export default AboutUs;
