import React from "react";
import "./teststack.css";
import { CaretRightOutlined } from "@ant-design/icons";
import {
  createBrowserRouter,
  RouterProvider,
  useLoaderData,
  Link,
} from "react-router-dom";

interface TeststackProps {
  [key: string]: any;
}

function Teststack(props: TeststackProps) {
  const { title, skills, location, type, id, showLink } = props;
  var topBorderStyle = "";
  if (showLink == "invisible") {
    topBorderStyle = "pointy-top-corners";
  } else {
    topBorderStyle = "";
  }
  let skillsArray: string[] = [];
  if (skills) {
    skillsArray = skills.split(",").map((skill: string) => skill.trim());
  }

  return (
    <>
      <div className={`div ${topBorderStyle}`}>
        <div className="div-2">
          <div className="div-3">{title}</div>
          <Link to={`/careers/${id}`} className={`div-4 ${showLink}`}>
            See More <CaretRightOutlined className="caret-right" />
          </Link>
        </div>
        <div className="div-6">
          <div className="div-7">{location}</div>
          <div className="div-10">{type}</div>
        </div>
        <div className="div-5">
          <div className="div-9">
            <div className="div-11">
              {skillsArray.map((skill: string, index: number) => (
                <div className="div-12" key={index}>
                  {skill}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Teststack;
