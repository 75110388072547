import { PlusOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import Logo from "../logo-component/Logo";
import "./form.css";
import "../../global.css";
import Prompt from "../promp-component/Prompt";

const { Title } = Typography;

interface TeststackProps {
  [key: string]: any;
}

function SignupForm(props: TeststackProps) {
  const { title } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(" ");
  const [showFormMessage, setShowFormMessage] = useState(false);
  const [showPopupBtn, setShowPopupBtn] = useState(false);
  const [emailData, setEmailData] = useState({
    job: title,
    firstname: "",
    lastname: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    inquiry: "",
    file: null as File | null,
    additionalFile: null as File | null,
    isValid: false,
  });

  useEffect(() => {
    setEmailData((prevData) => ({
      ...prevData,
      job: title,
    }));
  }, [title]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (name == "phoneNumber") {
      let formattedValue = value.replace(/[^\d+]/g, ""); // remove non-numeric characters except '+'

      // limit the input to a maximum of 14 characters
      if (formattedValue.length > 12) {
        formattedValue = formattedValue.slice(0, 12);
      }

      // format phone number with one dash after the third digit
      if (formattedValue.length > 3 && !formattedValue.startsWith("+")) {
        formattedValue =
          formattedValue.slice(0, 3) + "-" + formattedValue.slice(3);
      }

      // remove additional '+' characters
      formattedValue = formattedValue.replace(/\+/g, (match, offset) => {
        return offset ? "" : match; // retain the first '+' and remove any others
      });
      setEmailData({ ...emailData, [name]: formattedValue });
    } else {
      setEmailData({ ...emailData, [name]: value });
    }
  };

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      // Check if the file type is allowed
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      /**
       * Checks if the file type is allowed.
       * If not allowed, resets the field, alerts the user,
       * reloads the page, and returns early.
       * Allowed types are PDF, DOC, DOCX.
       */
      if (!allowedTypes.includes(file.type)) {
        setEmailData({ ...emailData, [fieldName]: null as File | null });
        alert("Please select a PDF, DOC, or DOCX file.");
        window.location.reload();
        return;
      }
      setEmailData({ ...emailData, [fieldName]: file });
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      !emailData.job ||
      !emailData.firstname ||
      !emailData.lastname ||
      !emailData.companyName ||
      !emailData.email ||
      !emailData.phoneNumber ||
      !emailData.inquiry ||
      !emailData.file
    ) {
      // alert("fill all required fields");
      setShowFormMessage(true);
      return;
    } else {
      setShowFormMessage(false);
      setSubmissionStatus("⏳ Please Wait...");
      setIsSubmitting(true);
      try {
        const formData = new FormData();
        formData.append("file", emailData.file as Blob);
        formData.append("additionalFile", emailData.additionalFile as Blob);

        /**
         * Extracts the file and additionalFile properties from the emailData object
         * into separate variables.
         *
         * The remaining properties are stringified and appended to the formData
         * object under the key "data".
         *
         * This allows the file attachments to be sent as FormData while the rest of the data
         * is sent as a JSON string.
         */
        const { file, additionalFile, ...dataWithoutFiles } = emailData;
        formData.append("data", JSON.stringify(dataWithoutFiles));

        const response = await fetch(
          "https://emailbe.onterragroup.com/career.php",
          {
            method: "POST",
            body: formData,
          }
        );
        console.log(response);

        if (response.ok) {
          console.log(response);
          // alert("Email sent successfully!");

          setSubmissionStatus("🎉 Application Sent Successfully!");
          setShowPopupBtn(true);
        } else {
          console.error("Email sending failed.");
          alert("Email sending failed.");
        }
      } catch (error) {
        console.error("An error occurred:", error);
        alert("An error occurred:");
      }
      setEmailData({
        job: title,
        firstname: "",
        lastname: "",
        companyName: "",
        email: "",
        phoneNumber: "",
        inquiry: "",
        file: null as File | null,
        additionalFile: null as File | null,
        isValid: false,
      });
      setTimeout(() => {}, 1700);
    }
  };

  return (
    <div id="form" className="bg-white text-black contactOuterCareers">
      <h3 className="CareerApplicationHeader">Apply For This Role</h3>
      <div>
        <div className="formOuterCareers ">
          <form onSubmit={handleSubmit}>
            <label className="careersFormLabel text-black" htmlFor="job">
              Title:{" "}
            </label>
            <input
              onChange={handleChange}
              name="job"
              value={emailData.job}
              className="border-grey border-thick text-black"
              type="text"
              readOnly
              required
              // placeholder="Job"
            />
            <label className="careersFormLabel text-black" htmlFor="firstname">
              First Name
              <span className="required-field text-primary text-primary">
                *
              </span>
              :
            </label>
            <input
              onChange={handleChange}
              name="firstname"
              value={emailData.firstname}
              className="border-grey border-thick text-black"
              type="text"
              placeholder="Your First Name"
              required
            />
            <label className="careersFormLabel text-black" htmlFor="lastname">
              Last Name<span className="test text-primary"> * </span>:
            </label>
            <input
              onChange={handleChange}
              name="lastname"
              value={emailData.lastname}
              className="border-grey border-thick text-black"
              type="text"
              placeholder="Your Last Name"
              required
            />

            <label className="careersFormLabel text-black" htmlFor="email">
              Email<span className="required-field text-primary"> * </span>:
            </label>
            <input
              onChange={handleChange}
              name="email"
              value={emailData.email}
              className="border-grey border-thick text-black"
              type="email"
              placeholder="Valid Email Address"
              required
            />

            <label
              className="careersFormLabel text-black"
              htmlFor="phoneNumber"
            >
              Phone<span className="required-field text-primary"> * </span>:
            </label>
            <input
              onChange={handleChange}
              name="phoneNumber"
              value={emailData.phoneNumber}
              className="border-grey border-thick text-black"
              type="tel"
              placeholder="Your Phone number"
              required
            />

            <label className="careersFormLabel text-black" htmlFor="file">
              Upload CV<span className="required-field text-primary"> * </span>{" "}
              <span className="text-grey filetypes">
                {"(PDF/DOC/DOCX only) "}
              </span>
              :
            </label>
            <input
              onChange={(e) => handleFileChange(e, "file")}
              name="file"
              type="file"
              accept=".pdf,.doc,.docx"
              className="border-grey border-thick text-black fileInput"
              required
            />
            {showFormMessage && (
              <div className="formMessage">
                {"Please Fill All Required Fields"}
              </div>
            )}

            <label
              className="careersFormLabel text-black"
              htmlFor="additionalFile"
            >
              Upload an Additional File
              <span className="text-grey filetypes">
                {" (PDF/DOC/DOCX only) "}
              </span>
              :
            </label>
            <input
              onChange={(e) => handleFileChange(e, "additionalFile")}
              name="additionalFile"
              type="file"
              accept=".pdf,.doc,.docx"
              className="border-grey border-thick  text-black fileInput"
            />

            <label
              className="careersFormLabel text-black"
              htmlFor="companyName"
            >
              If you were to be shortlisted for this role, how long of a notice
              period do you need for your current company? (days){" "}
              <span className="required-field text-primary"> * </span> :
            </label>
            <input
              onChange={handleChange}
              name="companyName"
              value={emailData.companyName}
              className="border-grey border-thick text-black"
              type="number"
              placeholder="** Days"
              required
            />

            <label className="careersFormLabel text-black" htmlFor="inquiry">
              Your Cover Letter
              <span className="required-field text-primary"> * </span>:
            </label>
            <textarea
              onChange={handleChange}
              name="inquiry"
              value={emailData.inquiry}
              className="border-grey border-thick text-black coverletter"
              placeholder="Your Cover Letter"
              required
            ></textarea>
            <input
              className="formBtn bg-primary text-white border-none"
              type="submit"
              value="SUBMIT"
            />
          </form>
        </div>
      </div>

      {/* Show Please Wait... */}
      {isSubmitting && (
        <Prompt showButton={showPopupBtn} message={submissionStatus} />
      )}
    </div>
  );
}

export default SignupForm;
