import React, { ReactNode, useState, useEffect } from "react";

import "../../App.css";
import "../../global.css";
import "./careers-about-onterra.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <div>Hello world!</div>,
  },
]);

function AboutOnterraCareers() {
  // Attach a scroll event listener to the window

  return (
    <div id="about_onterra" className="about_onterra text-black">
      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      ></meta>

      <div className="about_heading">About Onterra</div>
      <div id="career_list" className="about_body">
        Join Onterra Group, a rapidly expanding digital product engineering and
        AI solutions provider with a global footprint. We're not just delivering
        exceptional solutions for clients worldwide; we're also crafting our own
        innovative products in FinTech, E-commerce, and other dynamic sectors.
        At Onterra, we offer competitive salary and compensation packages,
        making us a standout employer in the market. Join our dynamic team of
        collaborative professionals driving digital transformations and
        redefining business paradigms for our clients.
      </div>
      <div className="about_heading we_r_hiring">We are hiring!</div>
      <div className="about_body">
        Looking for a new opportunity? Onterra Group is hiring! Explore our
        exciting job openings and take the next step in your career with us.
        Join a dynamic team that's revolutionizing industries and driving
        digital innovation. Apply now and be part of our success story!
      </div>
    </div>
  );
}

export default AboutOnterraCareers;
