import React from "react";
import "../../global.css";
import "./Solution-styles.css";
import { Button } from "antd";

function Solution(props: any) {
  return (
    <div className="solutionOuter">
      <div className="solutionSVG">{props.solutionImg}</div>

      <div className="solutionName text-darkblue-bg"><p>{props.solutionName}</p></div>
    </div>
  );
}

export default Solution;
