import React, { useEffect, useState } from "react";
import "./typewriter-styles.css"; // Import your CSS file for styling

const lines = ["End-to-end capabilities...", "Wide spectrum of technologies...", "Deeply experienced professionals...","Global exposure...","Strength in diversity..." ];

const Typewriter: React.FC = () => {
  const [currentLineIndex, setCurrentLineIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);

  const currentLine = lines[currentLineIndex];
  const displayText = currentLine.substring(0, charIndex);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isTyping) {
        if (charIndex <= currentLine.length) {
          setCharIndex((prevCharIndex) => prevCharIndex + 1);
        } else {
          setIsTyping(false);
          setTimeout(() => {
            setIsTyping(true);
            setCharIndex(0);
            setCurrentLineIndex((prevIndex) => (prevIndex + 1) % lines.length);
          }, 1000); // Delay before erasing or moving to next line
        }
      }
    }, 70); // Typing speed interval

    return () => clearInterval(interval);
  }, [charIndex, currentLine, currentLineIndex, isTyping]);

  return (
    <div>
      <div className="typewriter-text text-accent">{displayText}</div>
    </div>
  );
};

export default Typewriter;
