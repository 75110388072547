import React, { ReactNode, useState, useEffect } from "react";

import "../../App.css";
import "../../global.css";
import Navbar from "../navbar-component/Navbar";
import CareersBanner from "../careers-banner-component/CareersBanner";
import Footer from "../footer-component/Footer";
import WorkCulture from "../careers-work-culture-component/work-culture";
import CareersListing from "../careers-listing-component/careers-listing";
import AboutOnterraCareers from "../careers-about-onterra-component/careers-about-onterra";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <div>Hello world!</div>,
  },
]);

function Careers() {
  var scrollPercentage: number = 0;
  const [navBarPhase, setNavBarPhase] = useState(false);

  // Attach a scroll event listener to the window

  window.addEventListener("scroll", () => {
    // Calculate the scroll percentage
    scrollPercentage = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
    let scrollThreshold: number = 1.0;
    if (scrollPercentage > scrollThreshold) {
      setNavBarPhase(true);
    } else if (scrollPercentage <= scrollThreshold) {
      setNavBarPhase(false);
    }
  });
  

  return (
    <div className="App">
       
      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      ></meta>
      <Navbar phase={navBarPhase}></Navbar>
      

  
    
      <CareersBanner></CareersBanner>
      <AboutOnterraCareers></AboutOnterraCareers>
      <CareersListing/>
      <WorkCulture></WorkCulture>

  
      
      <Footer/>
    </div>
  );
}

export default Careers;
