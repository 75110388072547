import React from 'react';
import { Layout, Menu, Button } from 'antd';
import './Logo-styles.css';


function Logo(props:any) {
  return (
    <a href='/' className='logoOuter'>
      <p>
        <span className='on logoTxt'>on</span>
        <span className={`terra logoTxt ${props.terraColor ? props.terraColor : ''}`}>terra</span>
      </p>
    </a>
  );
}

export default Logo;
