import React from "react";
import "../../global.css";
import "./Tech-stack-styles.css";
import { Button } from "antd";
import Js from './img/js.png';
import Angular from './img/angular.png';
import aws from './img/aws.png';
import googleAnalytics from './img/googleAnalytics.png';
import meta from './img/meta.png';
import react from './img/react.png';
import openai from './img/OpenAI-Logo-PNG.png';
import llama from './img/llama.png';
import llangChain from './img/llangChain.png';
import python from './img/python.png';
import node from './img/node.png';
import mongo from './img/mongo.png';
import postgre from './img/postgre.png';
import azure from './img/azure.png';
import java from './img/java.png';
import cassandra from './img/cassandra.png';
import dotnet from './img/dotnet.png';
import rust from './img/rust.png';
import elasticdb from './img/elasticdb.png';
import gcp from './img/gcp.png';
import ts from './img/ts.png';
import powerBI from './img/powerBI.png';
import php from './img/php.png';
import mssql from './img/mssql.png';

function TeckStack(props: any) {
  return (
    <div className="bg-darkblue tech-stack-outer">
      <h4 className="expertsIn text-white">Experts In</h4>

    <div className="stack-outer image-grid  text-bold">
        <img src={Js} alt="" />
        <img src={ts} alt="" />
        <img src={Angular} alt="" />
        <img src={react} alt="" />
        <img src={dotnet} alt="" />
        <img src={node} alt="" />
        <img src={mongo} alt="" />
        <img src={postgre} alt="" />
        <img src={mssql} alt="" />
        <img src={elasticdb} alt="" />
        <img src={cassandra} alt="" />
        <img src={java} alt="" />

        <img src={python} alt="" />
        <img src={php} alt="" />

        {/* <img src={rust} alt="" /> */}
        <img src={powerBI} alt="" />

        <img src={azure} alt="" />
        <img src={aws} alt="" />
        <img src={gcp} alt="" />
        <img src={googleAnalytics} alt="" />
        {/* <img src={meta} alt="" /> */}
        <img src={llama} alt="" />
        <img src={openai} alt="" />

        <img src={llangChain} alt="" />
    </div> 
    <p className="text-white tech_stack_description"><span className="text-bold text-secondary">On</span><span className="text-bold text-accent">terra</span> continuously explores emerging technologies to ensure that you receive dependable, yet the most innovative solutions.</p>
    </div>
  );
}

export default TeckStack;
