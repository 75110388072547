import { PlusOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Typography } from "antd";
import Logo from "../logo-component/Logo";
import "./Footer-styles.css";
import "../../global.css";
import linkedInImage from "./images/linkedin.png";

const { Title } = Typography;

const Footer: React.FC = () => {
  return (
    <div className="bg-accent-dark text-white footerOuter">
      <div className="logoFooter logoFooterDesktop">
        <div className="logoFooterOuter">
          <Logo terraColor="text-darkblue-bg" />
          <span className="footerLogoRegMark text-darkblue-bg">&#174;</span>
        </div>
        <p className="rightsFooter">Developed By Onterra Group</p>
        <p className="rightsFooter">© 2023 All Rights Reserved </p>
      </div>

      <div className="footerColumns">
        <div className="footerColumn">
          <h5 className="text-darkblue-bg">Follow Us</h5>
          <a
            href="https://www.linkedin.com/company/onterra-group/mycompany/"
            target="_blank"
            className="social"
          >
            <img src={linkedInImage} alt="LinkedIn" />
          </a>
        </div>
        <div className="footerColumn">
          <h5 className="text-darkblue-bg">What we do</h5>
          <a href="/#solution-delivery" className="text-darkblue-bg">
            Solution Delivery
          </a>
          <a href="/#dedicated-teams" className="text-darkblue-bg">
            Dedicated Teams
          </a>
        </div>
        <div className="footerColumn">
          <h5
            className="text-darkblue-bg  https://www.instagram.com/ramesh_madara/"
            id="Hi"
          >
            Contact Us
          </h5>
          <a href="/#contact" className="text-darkblue-bg">
            Send an Inquiry
          </a>

          <a href="mailto:hello@onterragroup.com" className="text-darkblue-bg">
            hello@onterragroup.com
          </a>
          {/* <a href="tel:+941234567984" className="text-darkblue-bg">
            +94 770 718 808
          </a> */}
        </div>
        {/* <div className="footerColumn ">
        <h5 className="text-darkblue-bg  ">Find Us</h5>
        <a href="" className="text-darkblue-bg">Facebook</a>
        <a href="" className="text-darkblue-bg">Instagram</a>
        <a href="" className="text-darkblue-bg">Twitter</a>
      <nav  className="https://www.instagram.com/ramesh_madara  ">hi</nav>

      </div> */}
        <div className="logoFooter  logoFooterMobile">
          <div className="logoFooterOuter">
            <Logo terraColor="text-darkblue-bg" />
            <span className="footerLogoRegMark text-darkblue-bg">&#174;</span>
          </div>
          <p className="rightsFooter">Developed By Onterra Group</p>
          <p className="rightsFooter">© 2023 All Rights Reserved </p>
        </div>
      </div>
    </div>
  );
};

export default () => <Footer />;
