import React, { ReactNode, useState, useEffect } from "react";
import "./career-individual.css";
import "../../App.css";
import "../../global.css";
import Navbar from "../navbar-component/Navbar";
import CareersBanner from "../careers-banner-component/CareersBanner";
import Footer from "../footer-component/Footer";
import { careerData } from "../career-data/career-data";
import Teststack from "../careers-teststack/techstack";
import CareerDescription from "../careers-description-component/career-description";
import SignupForm from "../careers-form-component/form";

function CareerIndividual() {
  const [career, setCareer] = useState<any>(null);
  var scrollPercentage: number = 0;
  const [navBarPhase, setNavBarPhase] = useState(false);
  let lastSegment: string = "";

  useEffect(() => {
    const hashTerm = window.location.hash.substring(1); // Remove the '#' symbol
    if (hashTerm) {
      const element = document.getElementById(hashTerm);
      if (element) {
        const yOffset = 40; // Adjust the offset here
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, []);

  // Attach a scroll event listener to the window

  window.addEventListener("scroll", () => {
    scrollPercentage =
      (window.scrollY /
        (document.documentElement.scrollHeight - window.innerHeight)) *
      100;
    let scrollThreshold: number = 1.0;
    if (scrollPercentage > scrollThreshold) {
      setNavBarPhase(true);
    } else if (scrollPercentage <= scrollThreshold) {
      setNavBarPhase(false);
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    const path = window.location.pathname;
    lastSegment = path.substring(path.lastIndexOf("/") + 1);
    const careerItem = careerData.find((item) => item.id === lastSegment);
    if (!careerItem) {
      // console.log("Item not found in careerData");
      window.location.href = "/404";
    } else {
      // console.log("Item  found in careerData");
      // console.log(careerItem.status);
      if (careerItem.status == "active") {
        setCareer(careerItem);
      } else {
        window.location.href = "/404";
      }
    }
    // console.log(career.descriptions[0]);
  }, []);

  let scrollThreshold: number = 1.0;

  return (
    <div className="App">
      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      ></meta>
      <div></div>
      <Navbar phase={navBarPhase}></Navbar>
      {career && <CareersBanner title={career.title}></CareersBanner>}
      <div className="career-description">
        <Teststack {...career} showLink="invisible" />

        <div className="descriptionSection">
          {career &&
            career.descriptions &&
            career.descriptions.map((description: any, index: number) => (
              <CareerDescription
                key={index}
                heading={description.heading}
                points={description.points}
              />
            ))}
        </div>
      </div>
      {career && <SignupForm {...career}></SignupForm>}

      {career && <Footer />}
    </div>
  );
}

export default CareerIndividual;
