import { PlusOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Typography } from "antd";
import Logo from "../logo-component/Logo";
import "./Contact-styles.css";
import "../../global.css";

const { Title } = Typography;

const Contact: React.FC = () => {
  const [emailData, setEmailData] = useState({
    name: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    inquiry: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setEmailData({ ...emailData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://emailbe.onterragroup.com/inquiry.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(emailData),
        }
      );

      if (response.ok) {
        console.log("Email sent successfully!");
      } else {
        console.error("Email sending failed.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    setEmailData({
      name: "",
      companyName: "",
      email: "",
      phoneNumber: "",
      inquiry: "",
    });
  };

  return (
    <div className="bg-darkestblue text-white contactOuter">
      <div>
        <Title className="text-white contactHeader" level={1}>
          Contact us
        </Title>
        <div className="formOuter formMobile">
          <form onSubmit={handleSubmit}>
            <input
              onChange={handleChange}
              name="name"
              value={emailData.name}
              className="bg-darkestblue border-white text-white"
              type="text"
              placeholder="Your name"
            />
            <input
              onChange={handleChange}
              name="companyName"
              value={emailData.companyName}
              className="bg-darkestblue border-white text-white"
              type="text"
              placeholder="Your company name"
            />
            <input
              onChange={handleChange}
              name="email"
              value={emailData.email}
              className="bg-darkestblue border-white text-white"
              type="email"
              placeholder="Valid Email Address"
            />
            <input
              onChange={handleChange}
              name="phoneNumber"
              value={emailData.phoneNumber}
              className="bg-darkestblue border-white text-white"
              type="tel"
              placeholder="Your Phone number"
            />
            <textarea
              onChange={handleChange}
              name="inquiry"
              value={emailData.inquiry}
              className="bg-darkestblue border-white text-white"
              placeholder="Your Inquiry"
            ></textarea>
            <input
              className="formBtn bg-primary text-white border-none"
              type="submit"
              value="SUBMIT"
            />
          </form>
        </div>
        <div className="contactDetailsOuter">
          <div className="contactDetails">
            <h5 className="contactLabel">Email</h5>
            <a
              href="mailto:hello@onterragroup.com"
              className="contactDetail text-white"
            >
              hello@onterragroup.com
            </a>
            {/* <h5 className="contactLabel">Phone</h5>
            <a href="tel:+941234567984" className="contactDetail text-white">
              +94 770 718 808
            </a> */}
            <h5 className="contactLabel">Location</h5>
            <a className="contactDetail">
              193 1/C, Sri Gnanendra Mawatha, Nawala, Sri Lanka
            </a>
          </div>
        </div>
      </div>

      <div className="formOuter formDesktop">
        <form onSubmit={handleSubmit}>
          <input
            onChange={handleChange}
            name="name"
            value={emailData.name}
            className="bg-darkestblue border-white text-white"
            type="text"
            placeholder="Your name"
          />
          <input
            onChange={handleChange}
            name="companyName"
            value={emailData.companyName}
            className="bg-darkestblue border-white text-white"
            type="text"
            placeholder="Your company name"
          />
          <input
            onChange={handleChange}
            name="email"
            value={emailData.email}
            className="bg-darkestblue border-white text-white"
            type="email"
            placeholder="Valid Email Address"
          />
          <input
            onChange={handleChange}
            name="phoneNumber"
            value={emailData.phoneNumber}
            className="bg-darkestblue border-white text-white"
            type="tel"
            placeholder="Your Phone number"
          />
          <textarea
            onChange={handleChange}
            name="inquiry"
            value={emailData.inquiry}
            className="bg-darkestblue border-white text-white"
            placeholder="Your Inquiry"
          ></textarea>
          <input
            className="formBtn bg-primary text-white border-none"
            type="submit"
            value="SUBMIT"
          />
        </form>
      </div>
    </div>
  );
};

export default Contact;
