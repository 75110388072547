import React, { useEffect } from "react";
import Teststack from "../careers-teststack/techstack";
import "./career-listing.css";
import { careerData } from "../career-data/career-data";

function CareersListing() {
  useEffect(() => {
    const hashTerm = window.location.hash.substring(1); // Remove the '#' symbol
    if (hashTerm) {
      const element = document.getElementById(hashTerm);
      if (element) {
        const yOffset = 40; // Adjust the offset here
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, []);
  return (
    <div className="career_list">
      {careerData
        .filter((item) => item.status === "active")
        .map((props, index) => (
          <Teststack showLink="visible" key={index} {...props} />
        ))}
    </div>
  );
}

export default CareersListing;
