import React, { ReactNode, useState, useEffect, useRef } from "react";

import "./Navbar-styles.css";
import { Layout, Menu, Button } from "antd";
import { FloatButton } from "antd";
import Logo from "../logo-component/Logo";
import MenuItem from "antd/es/menu/MenuItem";
import "../../global.css";
import Sidebar from "../sidebar-component/Sidebar";

interface NavbarProps {
  phase: boolean;
}

function Navbar(props: NavbarProps) {
  const scrollLink = document.getElementById("scrollLink");

  document.addEventListener("DOMContentLoaded", function () {
    const scrollLink = document.getElementById("scrollLink");

    scrollLink?.addEventListener("click", function (event) {
      event.preventDefault(); // Prevent the default jump-to-anchor behavior

      const hrefValue = scrollLink?.getAttribute("href");
      if (hrefValue) {
        const targetElement = document.querySelector(hrefValue);
        if (targetElement) {
          targetElement.scrollIntoView({
            behavior: "smooth",
            block: "start", // Align to the top of the viewport
          });
        }
      }
    });
  });
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const toggleSideBar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  return (
    <div>
      {/* <Menu className="navOuter navPhase-1 " mode="horizontal" defaultSelectedKeys={["1"]}> */}
      <Menu
        className={props.phase ? "navOuter navPhase_2" : "navOuter navPhase_1"}
        mode="horizontal"
        defaultSelectedKeys={["1"]}
      >
        <div className="navItems">
          <Logo></Logo>

          <div className="nav-links">
            <Button
              id="scrollLink"
              href="/#solution-delivery"
              type="link"
              className="text-accent menu-btn"
            >
              Solution Delivery
            </Button>
            <Button
              href="/#dedicated-teams"
              type="link"
              className="text-accent menu-btn"
            >
              Dedicated Teams
            </Button>
            {/* <Button
              href="/careers"
              type="link"
              className="text-accent menu-btn"
            >
              Careers
            </Button> */}
            <Button href="/#abt" type="link" className="text-accent menu-btn">
              About Us
            </Button>
            <Button
              href="/#contact"
              className="border-secondary text-secondary menu-btn"
              type="link"
            >
              Contact Us
            </Button>
            <svg
              className="sidebarToggleBtn"
              onClick={() => toggleSideBar()}
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 384 512"
            >
              <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
            </svg>

            <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
          </div>
        </div>
      </Menu>
      <div className="red2"></div>
      <header className="App-header">
        <FloatButton.BackTop />
      </header>
      <Sidebar toggleFunc={toggleSideBar} toggle={sidebarToggle} />
    </div>
  );
}

export default Navbar;
